<template>
  <div>
    <a-modal
      width="500px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="账号信息"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <a-descriptions
        :column="1"
        class="simiot-descriptions"
      >
        <a-descriptions-item label="登录账号">
          {{ user.username }}
        </a-descriptions-item>

        <a-descriptions-item label="姓名">
          {{ user.full_name }}
        </a-descriptions-item>

        <a-descriptions-item label="手机号">
          {{ user.phone_number }}
        </a-descriptions-item>

        <a-descriptions-item label="联系邮箱">
          {{ user.email }}
        </a-descriptions-item>

        <a-descriptions-item label="角色">
          {{ user.role }}
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </div>
</template>

<script>
import { findUser } from '@/api/user'

export default {
  name: 'ShowUser',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      user: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findUser(this.id).then((res) => {
        this.user = res.data
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>
